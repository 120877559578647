<template>
    <div class="notifications">
        <span class="overlay" :class="{active: isMenuOpen || isAccessModalOpen}"></span>
        <Nav />
        <div class="header header-standard">
            <HeaderNavBar back />
        </div><!-- header -->
        <div class="header-image grey"></div><!-- header image -->
        <div class="content-wrap content-wrap-alt content-wrap-nav">
            <SectionTitle
                icon="NotificationIcon"
                title="Notifications"
                colour="red"
            />
            <div
                v-for="(data, date) in notifications"
                :key="date"
                class="section-gap notification-card"
            >
                <h1>{{ formatDate(date) }}</h1><br>
                <CardRow
                    v-for="notification in data"
                    :key="notification.data.id"
                    class=""
                    icon="ContactIcon"
                    :tag="!notification.read_at ? '' : 'New'"
                    :eyebrow="true"
                    :type="notification.data.type"
                    :title="notification.data.title"
                    :text="notification.data.content"
                    :routeName="getRoute(notification.data.type)"
                    :id="parseInt(notification.data.modelId)"
                />
            </div>
        </div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

        <Menu />
    </div>
</template>
<script>
import HeaderNavBar from '@/components/HeaderNavBar.vue';
import Nav from '@/components/Nav.vue';
import Menu from '@/components/Menu.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import UserService from '@/services/UserService';
import CardRow from '@/components/CardRow';
import RegisterIcon from '@/assets/register.svg';
import ProfileIcon from '@/assets/profile.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import Button from '@/components/Button.vue';
export default {
    name: 'Notifications',
    components: {
        Nav,
        HeaderNavBar,
        Menu,
        SectionTitle,
        CardRow,
        RegisterIcon,
        ProfileIcon,
        GreyCloseIcon,
        Button
    },
    data () {
        return {
            notifications: []
        };
    },
    methods: {
        getNotifications () {
            UserService.getNotifications().then((response) => {
                this.notifications = response.data;
            });
        },
        formatDate (date) {
            date = this.$moment(date).format('ddd Do MMM');
            if (date === this.$moment().format('ddd Do MMM')) {
                date = 'Today';
            }
            if (date === this.$moment().subtract(1, 'day').format('ddd Do MMM')) {
                date = 'Yesterday';
            }
            return date;
        },
        getRoute (type) {
            switch (type) {
                case 'Requests':
                    return 'RequestsSingle';
                default: return null;
            }
        },
        openLoginScreen () {
            this.$router.push({ name: 'Home', params: { showLoginModal: true } });
        },
        closeAccessModal () {
            this.$store.state.isAccessModalOpen = false;
        }
    },
    computed: {
        isMenuOpen () {
            return this.$store.state.isMenuOpen;
        },
        isAccessModalOpen () {
            return this.$store.state.isAccessModalOpen;
        },
        isAuthenticated () {
            return this.$store.getters.isAuthenticated;
        }
    },
    mounted () {
        if (this.isAuthenticated) {
            this.getNotifications();
        }
        this.$store.commit('SET_NOTIFICATIONS', false);
    }
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
</style>
